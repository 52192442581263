<template>
  <div>

    <div
      v-for="organizer in organizers"
      :key="organizer.Id"
      class="card has-margin-bottom-x2"
    >
      <div class="card-content">
        <div class="media">
          <div class="media-left is-hidden-mobile">
            <figure class="image is-64x64">
              <img
                class="is-rounded has-shadow has-background-grey-light"
                src="@/assets/img/spacer1_1.gif"
                :style="{backgroundImage: `url(${$options.filters.getProfileImage(organizer.Image, organizer.ProfileId, 84)})`}"
                alt="Placeholder image"
              >
            </figure>
          </div>
          <div class="media-content">
            <transition
              name="slide-fade"
              mode="out-in"
            >
              <!-- DETAIL: Edit organizer -->
              <div
                key="detail"
                v-if="organizerToEditId !== organizer.Id && organizerToDeleteId !== organizer.Id"
              >
                <p
                  class="has-margin-bottom-half title is-5"
                  :class="{ 'has-margin-top' : !organizer.Phone}"
                >
                  <span
                    v-if="organizer.IsPrimary"
                    class="has-text-warning"
                  >
                    <i class="fas fa-star"></i>
                  </span>
                  <span>{{ organizer.Name }}</span>
                </p>

                <div class="content">
                  <span v-if="organizer.Email !== ''">{{ organizer.Email }}<br /></span>
                  <span v-if="organizer.Phone !== ''">{{ organizer.Phone }}</span>
                </div>
              </div>
              <!-- END -->

              <!-- FORM: Edit organizer -->
              <div
                key="edit"
                v-if="organizerToEdit && organizerToEditId === organizer.Id"
              >
                <div class="columns is-multiline">
                  <div class="column is-paddingless-bottom is-full-mobile is-half-tablet">
                    <div class="field">
                      <label class="label">Name</label>
                      <div class="control">
                        <input
                          type="text"
                          v-model="organizerToEdit.Name"
                          placeholder="Name"
                          class="input"
                          :readonly="organizerToEdit.ProfileId > 0"
                          :disabled="organizerToEdit.ProfileId > 0"
                        >
                      </div>
                    </div>
                  </div>
                  <div class="column is-paddingless-bottom is-full-mobile is-half-tablet">
                    <div class="field">
                      <label class="label">Email</label>
                      <div class="control">
                        <input
                          type="text"
                          v-model="organizerToEdit.Email"
                          placeholder="Email"
                          class="input"
                          :readonly="organizerToEdit.ProfileId > 0"
                          :disabled="organizerToEdit.ProfileId > 0"
                        >
                      </div>
                    </div>
                  </div>
                  <div class="column is-full-mobile is-half-tablet">
                    <div class="field">
                      <label class="label">Phone</label>
                      <div class="control">
                        <input
                          type="text"
                          v-model="organizerToEdit.Phone"
                          placeholder="Phone"
                          class="input"
                        >
                      </div>
                    </div>
                  </div>
                  <div class="column is-full-mobile is-half-tablet">
                    <div
                      class="has-margin-top-half has-margin-bottom-half"
                      v-if="organizerToEdit.Id > 0"
                    >
                      <ui-checkbox
                        v-model="organizerToEdit.ReceiveCheckinMail"
                        inputAdditionClass="is-small"
                        iconAdditionClass="is-small has-text-white"
                      >
                        Receive check-in mail
                      </ui-checkbox>
                    </div>

                    <div v-if="organizerToEdit.Id > 0 && !organizer.IsPrimary && organizers.length">
                      <ui-checkbox
                        v-model="organizerToEdit.IsPrimary"
                        inputAdditionClass="is-small"
                        iconAdditionClass="is-small has-text-white"
                      >
                        Show as organizer in event
                      </ui-checkbox>
                    </div>
                  </div>
                </div>

                <div class="buttons-container is-right">
                  <a
                    v-if="organizerToEdit.Id > 0"
                    @click="updateOrganizer"
                    class="button has-margin-right is-success"
                  >Save</a>

                  <a
                    v-if="organizerToEdit.Id === 0"
                    @click="createOrganizer"
                    class="button has-margin-right is-success"
                  >Create</a>

                  <a
                    v-if="organizerToEdit.Id > 0"
                    @click="cancelEditOrganizer"
                    class="button is-white"
                  >
                    <span>Cancel</span></a>

                  <a
                    v-if="organizerToEdit.Id === 0"
                    @click="cancelCreateOrganizer"
                    class="button is-white"
                  >
                    <span>Cancel</span></a>
                </div>
              </div>
              <!-- END -->

              <!-- DELETE organizer -->
              <div
                key="delete"
                v-if="organizerToDelete && organizerToDeleteId === organizer.Id"
              >
                <p class="has-margin-top title is-5">Are you sure you want to remove <u>{{organizerToDelete.Name}}</u> as organizer?</p>
                <div class="buttons-container is-right">
                  <a
                    @click="deleteOrganizer"
                    class="button is-danger has-margin-right"
                  >Remove</a>

                  <a
                    class="button is-white"
                    @click="cancelDeleteOrganizer"
                  >Cancel</a>
                </div>
              </div>
              <!-- END -->
            </transition>
          </div>
          <transition
            name="slide-fade"
            mode="out-in"
          >
            <div
              key="detail"
              v-if="organizerToEditId !== organizer.Id && organizerToDeleteId !== organizer.Id"
              class="media-right content"
            >
              <p>
                <a
                  @click="setEditOrganizer(organizer)"
                  class="button is-primary is-fullwidth"
                >
                  <span class="icon">
                    <i class="fas fa-pencil-alt"></i></span>
                  <span>Edit</span></a>
              </p>
              <p v-if="!organizer.IsPrimary && organizers.length">
                <a
                  @click="setDeleteOrganizer(organizer)"
                  class="has-text-danger"
                >
                  <span class="icon">
                    <i class="far fa-trash-alt"></i></span>
                  <span>Remove</span></a>
              </p>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <!-- <a
      @click="setCreateOrganizer"
      class="button is-primary"
    >Create organizer</a> -->

  </div>
</template>

<script>
import eventProvider from '@/providers/event'

export default {
  props: {
    eventId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      organizers: [],
      organizerToDelete: null,
      organizerToDeleteId: 0,
      organizerToEdit: null,
      organizerToEditId: 0
    }
  },

  created() {
    this.getEventOrganizers()
  },

  methods: {
    getEventOrganizers() {
      eventProvider.methods.getEventOrganizers(this.eventId).then(response => {
        if (response.status === 200) {
          this.organizers = response.data
        }
      })
    },

    cancelDeleteOrganizer() {
      this.organizerToDelete = null
      this.organizerToDeleteId = 0
    },

    setDeleteOrganizer(organizer) {
      this.organizerToDelete = organizer
      this.organizerToDeleteId = organizer.Id
    },

    deleteOrganizer() {
      eventProvider.methods.deleteEventOrganizer(this.organizerToDelete).then(response => {
        if (response.status === 204) {
          let organizerIndex = this.organizers.findIndex(o => o.Id === this.organizerToDeleteId)

          if (organizerIndex > -1) {
            this.organizers.splice(organizerIndex, 1)
          }
        }
      })
    },

    cancelCreateOrganizer() {
      let organizerIndex = this.organizers.findIndex(o => o.Id === 0)

      if (organizerIndex > -1) {
        this.organizers.splice(organizerIndex, 1)
      }

      this.organizerToEdit = null
      this.organizerToEditId = 0
    },

    cancelEditOrganizer() {
      this.organizerToEdit = null
      this.organizerToEditId = 0
    },

    setEditOrganizer(organizer) {
      this.organizerToEdit = JSON.parse(JSON.stringify(organizer))
      this.organizerToEditId = organizer.Id
    },

    setCreateOrganizer() {
      let newOrganizer = {
        Id: 0,
        EventId: this.eventId,
        ProfileId: 0,
        Name: '',
        Image: '',
        Email: '',
        Phone: '',
        ReceiveCheckinMail: true,
        IsPrimary: false
      }

      this.organizers.unshift(newOrganizer)
      this.organizerToEdit = newOrganizer
    },

    createOrganizer() {
      eventProvider.methods.createEventOrganizer(this.organizerToEdit).then(response => {
        if (response.status === 201) {
          let organizerIndex = this.organizers.findIndex(o => o.Id === 0)

          if (organizerIndex > -1) {
            this.organizers[organizerIndex] = response.data

            this.organizerToEdit = null
            this.organizerToEditId = 0
          }
        }
      })
    },

    updateOrganizer() {
      eventProvider.methods.updateEventOrganizer(this.organizerToEdit).then(response => {
        if (response.status === 200) {
          if (this.organizerToEdit.IsPrimary) {
            let otherOrganizers = this.organizers.filter(o => o.Id !== this.organizerToEditId)
            for (let i = 0; i <= otherOrganizers.length; i++) {
              this.organizers[i].IsPrimary = false
            }
          }

          let organizerIndex = this.organizers.findIndex(o => o.Id === this.organizerToEditId)

          if (organizerIndex > -1) {
            this.organizers[organizerIndex] = JSON.parse(JSON.stringify(this.organizerToEdit))

            this.organizerToEdit = null
            this.organizerToEditId = 0
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button {
  min-width: 80px;
}
</style>
